import * as Popover from '@radix-ui/react-popover';
import { AnimatePresence } from 'framer-motion';
import { ChevronDown } from 'lucide-react';
import { signIn, useSession } from 'next-auth/react';
import { useState } from 'react';
import { useRecoilValue } from 'recoil';

import { Button } from '@/components/button';
import { FeaturesToggle } from '@/components/features-toggle';
import { Icon } from '@/components/icon';
import { UserDropdown } from '@/components/modules/user/dropdown';
import { useFeatureFlag } from '@/hooks/use-feature-flag';
import { marketingPages } from '@/lib/constants/marketing-site';
import { featuresState } from '@/state/features';
import { navIsOpenState } from '@/state/navigation';
import { tv } from '@/utils/styles';
import { trackUser } from '@/utils/tracking';

import { FeedbackPopover } from './feedback-popover';
import { UserAvatar } from './user-avatar';

export interface UserNavProps {
  variant?: 'light' | 'dark';
  isAppHeader?: boolean;
  hideUsageOnSmallScreens?: boolean;
}

const shouldShowQuotesButton = (variant?: string | boolean) => {
  return !(!variant || variant === 'button-hidden');
};

const UserNav = ({ variant, isAppHeader = false, hideUsageOnSmallScreens }: UserNavProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [feedbackIsOpen, setFeedbackIsOpen] = useState<boolean>(false);
  const navIsOpen = useRecoilValue(navIsOpenState);
  const features = useRecoilValue(featuresState);
  const { data: session, status } = useSession();

  const USER_AUTH_ON = !!features.userAuthentication;
  const SUBSCRIPTIONS_ON = !!features.subscriptionsOn;

  // Feature flags / Experiment variants
  const { variant: SHOW_QUOTES_BUTTON_VARIANT } = useFeatureFlag('hide-quotes-button');
  const SHOW_QUOTES_BUTTON = shouldShowQuotesButton(SHOW_QUOTES_BUTTON_VARIANT);

  const handleSignIn = () => {
    signIn();

    trackUser.event('Go to: Sign-in', {
      location: 'Nav',
    });
  };

  const { base, userIcon, userNavItems, userNavItem, popoverTrigger, popoverBadgeTrigger, profileBadgeAvatar } =
    userNavStyles({
      navIsOpen,
    });

  const renderUserPopover = () => {
    return (
      <Popover.Root onOpenChange={() => setIsOpen(!isOpen)} modal>
        {USER_AUTH_ON && (
          <Popover.Trigger className={popoverBadgeTrigger()}>
            {session ? (
              <div className={profileBadgeAvatar({ variant })}>
                <UserAvatar user={session.user} />
              </div>
            ) : null}
            <ChevronDown className="mr-1.5 size-3 text-white" />
          </Popover.Trigger>
        )}
        <AnimatePresence>
          {isOpen && session && (
            <Popover.Portal>
              <Popover.Content sideOffset={10} forceMount className="z-5">
                <UserDropdown
                  isOpen
                  session={session}
                  showUpgrade={SUBSCRIPTIONS_ON}
                  hideUsageOnSmallScreens={hideUsageOnSmallScreens}
                />
              </Popover.Content>
            </Popover.Portal>
          )}
        </AnimatePresence>
      </Popover.Root>
    );
  };

  const renderFeedbackPopover = () => {
    return (
      <Popover.Root onOpenChange={() => setFeedbackIsOpen(!feedbackIsOpen)}>
        <Popover.Trigger asChild className={popoverTrigger()}>
          <Button
            iconRight="speech-bubble"
            className={pageNavButton({
              variant: 'dark',
              isActive: feedbackIsOpen,
            })}
          >
            Feedback
          </Button>
        </Popover.Trigger>

        <AnimatePresence>
          {feedbackIsOpen && session && (
            <FeedbackPopover session={session} status={status} isOpen setFeedbackIsOpen={setFeedbackIsOpen} />
          )}
        </AnimatePresence>
      </Popover.Root>
    );
  };

  return (
    <div className={base()}>
      <ul className={userNavItems()}>
        {isAppHeader && <li className={userNavItem()}>{renderFeedbackPopover()}</li>}
        {SHOW_QUOTES_BUTTON && !isAppHeader && (
          <li className={userNavItem()}>
            <Button href={marketingPages.getQuote.url} className={pageNavButton({ variant: 'light' })}>
              Shipping quote
            </Button>
          </li>
        )}
        {session && !isAppHeader && (
          <li className={userNavItem()}>
            <Button href="/dashboard" className={pageNavButton({ variant: 'action' })}>
              Go to App
            </Button>
          </li>
        )}
        {USER_AUTH_ON && session && <li className={userNavItem()}>{renderUserPopover()}</li>}
        {USER_AUTH_ON && !session && (
          <li className={userNavItem()}>
            <Button onClick={() => handleSignIn()} className={pageNavButton()}>
              <span className="flex items-center">
                Sign in
                <Icon name="user" size="16px" className={userIcon()} />
              </span>
            </Button>
          </li>
        )}
        {!USER_AUTH_ON && !session && (
          <li className={userNavItem()}>
            <Button disabled className={pageNavButton()}>
              Sign in
            </Button>
          </li>
        )}
      </ul>
      <FeaturesToggle />
    </div>
  );
};

export const userNavStyles = tv({
  slots: {
    base: 'z-2 flex w-[calc(100%-theme(spacing.1))] self-start max-md:border-t max-md:border-grey-200 max-md:pl-2 max-md:opacity-0 max-md:transition-opacity max-md:delay-0 max-md:duration-300 max-md:ease-in-out md:relative md:mr-3 md:mt-0 md:w-auto md:items-center md:self-center md:pl-4',
    userIcon: '-mr-2 ml-2 grid h-6 w-6 place-items-center rounded-full bg-white [&_path]:fill-blue-600',
    userNavItems: 'flex max-md:w-full max-md:flex-col',
    userNavItem: '!mr-0 !pl-0 max-md:border-grey-200 max-md:py-xs [&+li]:md:ml-4 [&:not(:last-child)]:max-md:border-b',
    popoverTrigger: 'block',
    popoverBadgeTrigger: 'flex items-center rounded-2xl bg-blue-800 hover:bg-blue-900',
    profileBadgeAvatar: 'flex h-8 w-8 items-center justify-center rounded-full border-2 border-blue-800 bg-white',
  },
  variants: {
    variant: {
      light: {},
      dark: {},
    },
    navIsOpen: {
      true: {
        base: 'max-md:opacity-100 max-md:delay-300',
      },
    },
  },
});

const pageNavButton = tv({
  base: '!flex h-lg items-center rounded-2xl border-0 bg-lightBlue-500 pl-3 pr-3 text-baseSm font-medium text-white outline outline-1 outline-brandDarkBlue transition-shadow duration-200 ease-in-out disabled:!cursor-not-allowed max-md:justify-start max-md:rounded-none max-md:border-0 max-md:!bg-transparent max-md:p-0 max-md:text-left max-md:text-base max-md:font-normal max-md:text-blue-700 max-md:outline-none max-md:active:bg-transparent max-md:hocus:bg-transparent sm:px-0 md:pl-3 md:pr-3 [&_svg_path]:max-md:fill-blue-600',
  variants: {
    variant: {
      light: 'bg-lightBlue-100 text-blue-700 hover:bg-white',
      dark: 'border-blue-900 bg-blue-900 hover:bg-black',
      action: 'border-yellowDark bg-yellowDark text-text-primary hover:bg-yellowMid',
    },
    isActive: {
      true: 'bg-black',
    },
  },
});

export { UserNav };
